import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  isExpanded?: boolean
  time?: string
  title?: string
}

export const Item = memo(function Item({ description, time, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="expanded">
      <Head>
        <Title>
          {title}
          <Button>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Button>
        </Title>
      </Head>
      {description || time ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Wrapper>
                      {time ? <Time>{time}</Time> : null}
                      {description ? (
                        <Description
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      ) : null}
                    </Wrapper>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 0.5rem;
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0875rem;
  line-height: 1.0625rem;
  padding: 1.6875rem 2.25rem 1.5rem 2.125rem;
  position: relative;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    padding-top: 1.75rem;
    padding-bottom: 1.5rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`

const Button = styled.div`
  margin-left: auto;
  padding-left: 1rem;

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight3};
    stroke-width: 1.3;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  overflow: hidden;
  padding: 0 2.125rem;

  @media (max-width: 767px) {
    padding: 0 1.875rem;
  }
`

const Wrapper = styled.div`
  padding: 1.25rem 0;
`

const Time = styled.div`
  font-weight: 600;
  margin-bottom: 0.75rem;
`

const Description = styled.div`
  p {
    margin-block-end: 1em;
  }

  ul {
    margin: 1em 0;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 1rem 0 1.875rem;
  }
`
