import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

export interface Props {
  cta: ButtonProps
  title?: string
}

export const Download = memo(function Download({ cta, title }: Props) {
  if (!cta) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      <CTA variant="outline" {...cta} />
    </Container>
  )
})

const Container = styled.section`
  max-width: 25.375rem;
  margin: 12.5rem auto 10.625rem;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: none;
    margin: 7.5rem auto 5.625rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  justify-content: center;
  margin-top: 1.875rem;
  &:after {
    display: none;
  }
`
