import styled from '@emotion/styled'
import { Section } from 'app/components/Common/Section'
import React, { memo } from 'react'

import { List, Props as ListProps } from './List'

export interface Props {
  languageCode: string
  list: ListProps[]
}

export const Accordions = memo(function Accordions({
  languageCode,
  list,
}: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      <Section label="treatments" languageCode={languageCode} />

      <Wrapper>
        {list.map((item, index) => (
          <List key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 17rem auto 9rem;
  padding: 9rem 14.028vw;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 9.375rem auto 5.625rem;
    padding: 5rem 1.875rem 3.75rem;
  }

  @media (max-width: 767px) {
    margin: 7.5rem auto 3.75rem;
    padding-right: 0;
    padding-left: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`
