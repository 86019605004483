import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  item: ItemProps[]
  title?: string
}

export const List = memo(function List({ item, title }: Props) {
  if (!item) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      <AccordionsWrap allowZeroExpanded>
        {item.map((e, i) => (
          <Item key={i} {...e} />
        ))}
      </AccordionsWrap>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 6.25rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 5rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0 1.875rem;
  }
`

const AccordionsWrap = styled(Accordion)`
  margin-top: 2rem;

  > div {
    &.expanded {
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      margin-top: 0.5rem;
      transition: background 0.4s ease-in-out;
    }
  }
`
